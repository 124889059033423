/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:37:50
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 17:39:48
 */
import ChartsList from './chartsList.vue'
export default ChartsList
