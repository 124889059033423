/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:38:02
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 20:22:17
 */
import { createNamespacedHelpers } from 'vuex'
import DeviceSystem from './components/deviceSystem'
import Age from './components/age'
import Gender from './components/gender'
import Region from './components/region'
import Interest from './components/interest'
import AppLike from './components/appLike'
import Enducation from './components/enducation'
import Marriage from './components/marriage'
import Procreation from './components/procreation'
import Career from './components/career'
import Car from './components/car'
import MobileBrand from './components/mobileBrand'
import MobileType from './components/mobileType'

const { mapGetters } = createNamespacedHelpers('Operate/Datapackage/Crowdattribute')

export default {
  name: 'ChartsList',
  components: {
    DeviceSystem,
    Gender,
    Age,
    Region,
    Interest,
    AppLike,
    Enducation,
    Marriage,
    Procreation,
    Career,
    Car,
    MobileBrand,
    MobileType
  },
  data () {
    return {
    }
  },
  computed: {

    ...mapGetters([
      'crowdattributeList',
      'getCrowdattribute'
    ])
  },
  mounted () {
  },
  methods: {
  }
}
