/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:45:40
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:16:24
 */
import AppLike from './appLike.vue'
export default AppLike
