/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:52:00
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-07 11:45:24
 */
import ChartDataTool from '@/service/chartDataTool'
import ChartContainer from '../modules/chartContainer'
import MapChart from '../modules/mapChart'

export default {
  name: 'Region',
  components: {
    ChartContainer,
    MapChart
  },
  data () {
    return {
    }
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    chartData () {
      const self = this
      if (!Array.isArray(self.data) || self.data.length === 0) {
        return []
      }
      const tool = new ChartDataTool()
      return self.data.map(({ label_name: name, user_count: count, total }) => ({
        name,
        value: tool.getRate(count, total)
      }))
    }
  }
}
