/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 10:48:05
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 16:50:17
 */
import echarts from 'echarts'
import china from 'echarts/map/json/china.json'
import NoData from '../noData'

echarts.registerMap('china', china)

export default {
  name: 'MapChart',
  components: {
    NoData
  },
  data () {
    return {
      // 地图基本配置
      baseOption: {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#FEFEFE',
          extraCssText: 'min-width: 88px;padding: 8px 16px;box-shadow: 0px 8px 16px 0px rgba(44, 104, 255, 0.16);',
          textStyle: {
            fontSize: 12,
            color: 'rgba(44, 104, 255, 0.7)'
          },
          position (point, params, dom, rect, size) {
            // 固定在顶部
            return [point[0] - (size.contentSize[0] / 2) - 2, point[1] - size.contentSize[1] - 12]
          },
          formatter (e, t, n) {
            return e.name + '<br/><span style="font-size: 16px;color: rgba(44, 104, 255, 1);">' + e.value + '%</span><span style="position: absolute;width: 10px;height: 10px;background:#ffffff;bottom:-5px;left: 50%;transform: translateX(-50%) rotate(45deg) skewX(10deg);"></span>'
          }
        },
        backgroundColor: '#ffffff',
        visualMap: {
          min: 0,
          max: 1000,
          left: 26,
          bottom: 16,
          showLabel: !0,
          text: ['用户占比'],
          textStyle: {
            fontSize: 13,
            color: '#13234E'
          },
          pieces: [
            {
              gt: 5,
              label: '≥5%',
              color: new echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                  { offset: 0, color: '#47BDFF' },
                  { offset: 1, color: '#2C68FF' }

                ])
            }, {
              gte: 3,
              lte: 5,
              label: '3%-5% (含3%)',
              color: new echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                  { offset: 0, color: '#58F6F9' },
                  { offset: 1, color: '#14C6CA' }

                ])
            }, {
              gte: 1,
              lt: 3,
              label: '1%-3% (含1%)',
              color: new echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                  { offset: 0, color: 'rgba(71, 189, 255, 0.5)' },
                  { offset: 1, color: 'rgba(44, 104, 255, 0.5)' }
                ])
            }, {
              lt: 1,
              label: '<1%',
              color: '#E6E6E6'
            }
          ],
          show: !0
        },
        geo: {
          map: 'china',
          roam: !1,
          scaleLimit: {
            min: 1,
            max: 2
          },
          zoom: 1,
          top: 20,
          label: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            borderColor: '#ffffff',
            borderWidth: 2
          },
          emphasis: {
            label: {
              show: false
            },
            itemStyle: {
              areaColor: new echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                  { offset: 0, color: '#516598' },
                  { offset: 1, color: '#1E2537' }

                ]
              ),
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderWidth: 2
            }
          }
        }
        // series: [{
        //   name: '用户占比',
        //   type: 'map',
        //   geoIndex: 0,
        //   data
        // }]
      }
    }
  },
  props: {
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 360
    },
    data: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    data () {
      if (Array.isArray(this.data) && this.data.length > 0) {
        this.$nextTick(() => {
          this.createMapChart()
        })
      }
    }
  },
  mounted () {
    // 生成地图
    console.log(this.data)
    if (Array.isArray(this.data) && this.data.length > 0) {
      this.createMapChart()
    }
  },
  methods: {

    /**
     * 生成数据配置
     */
    seriesOption (data) {
      return [{
        name: '用户占比',
        type: 'map',
        geoIndex: 0,
        data
      }]
    },

    /**
     * 生成地图配置
     */
    echartsOption (data) {
      const self = this
      return Object.assign({}, self.baseOption, { series: self.seriesOption(data) })
    },

    /**
     * 绘制地图
     */
    createMapChart () {
      const echartsOption = this.echartsOption(this.data)
      const chartInstance = echarts.init(this.$refs.chart)

      chartInstance.setOption(echartsOption)
    }
  }
}
