import { render, staticRenderFns } from "./mobileBrand.vue?vue&type=template&id=8fb3f106&scoped=true&"
import script from "./mobileBrand.js?vue&type=script&lang=js&"
export * from "./mobileBrand.js?vue&type=script&lang=js&"
import style0 from "./mobileBrand.scss?vue&type=style&index=0&id=8fb3f106&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fb3f106",
  null
  
)

export default component.exports