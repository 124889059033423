/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:49:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 20:00:42
 */
import Interest from './interest.vue'
export default Interest
